<template>
  <v-row no-gutters class="fill-height py-5 px-2">
    <v-col cols="6">
      <v-text-field label="Search by Session ID" v-model="search" single-line append-icon="mdi-magnify" clearable
        class="mx-1"></v-text-field>
      <v-data-table :search="search" @click:row="getTimeline" fixed-header :items-per-page="-1" hide-default-footer
        item-key="id" single-select sort-by="userSessionDate" :sort-desc="true"
        :height="kioskAnalyticsDimension.userSessionheight - 80" :headers="userSessionHeader" :items="userSessionList"
        loading-text="Loading... Please wait" :loading="loading" :item-class="getRowClass">
        <template v-slot:item.id="{ item }">
          {{ item.id }}
        </template>
        <template v-slot:item.userSessionDate="{ item }">
          {{ item.userSessionDate | formatDate }}
        </template>
        <template v-slot:item.userSessionDuration="{ item }">
          {{ item.userSessionDuration | formatDuration }}
        </template>
        <template v-slot:item.userSessionStartTime="{ item }">
          {{ item.userSessionStartTime | formatTime }}
        </template>
        <template v-slot:item.userSessionEndTime="{ item }">
          {{ item.userSessionEndTime | formatTime }}
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="6" align="center">
      <h5 class="text-h5 primary--text">Session Timeline</h5>
      <span v-if="timeline === null">Select a session to view the timeline.</span>
      <v-card v-else class="pr-3 my-3 overflow-y-auto" :height="kioskAnalyticsDimension.userSessionheight - 85" flat>
        <span v-if="timeline.length === 0">
          User detected but no interaction took place.
        </span>
        <v-timeline v-else dense clipped class="my-1">
          <!-- <v-virtual-scroll
            :height="viewPortHeight"
            item-height="120"
            bench="0"
            :items="timeline"
          >
            <template v-slot:default="{ item }"> -->
          <div v-for="(item, i) in timeline" :key="i">
            <v-timeline-item v-if="item.actionType.toLowerCase() === 'session started'" :key="Math.random()"
              color="success" icon="mdi-login-variant" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <div class="text-subtitle-1 font-weight-medium my-3">{{
                    item.actionType
                  }}</div>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item>
            <v-timeline-item v-if="item.actionType.toLowerCase() === 'end session'" :key="Math.random()" color="error"
              icon="mdi-logout-variant" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">

                  <div class="text-subtitle-1 font-weight-medium my-3">{{
                    item.actionType
                  }}</div>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
            </v-timeline-item>

            <v-timeline-item v-else-if="item.actionType.toLowerCase() === 'speech'" :key="Math.random()"
              color="orange lighten-2" icon="mdi-bullhorn-outline" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <span class="caption">Module: </span>
                  <span class="caption font-weight-medium">{{
                    item.module
                  }}</span>
                  <br />
                  <span class="caption">Question: </span>
                  <span class="caption font-weight-medium">{{
                    item.action
                  }}</span>
                  <br />
                  <span class="caption">Response: </span>
                  <span class="caption font-weight-medium">{{
                    item.response | trimLength
                  }}</span>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item>

            <v-timeline-item v-else-if="item.actionType.toLowerCase() === 'touch'" :key="Math.random()"
              color="blue-grey lighten-2" icon="mdi-gesture-tap" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <span class="caption">Action: </span>
                  <span class="caption font-weight-medium">Touch</span>
                  <br />
                  <span class="caption">Target: </span>
                  <span class="caption font-weight-medium">{{
                    item.response
                  }}</span>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item>

            <v-timeline-item v-else-if="item.actionType.toLowerCase() === 'payment'" :key="Math.random()"
              color="blue-grey lighten-2" icon="mdi-account-credit-card-outline" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <span class="caption">Action: </span>
                  <span class="caption font-weight-medium">{{
                    item.actionType
                  }}</span>
                  <br />
                  <span class="caption">Amount: </span>
                  <span class="caption font-weight-medium">{{
                    item.amount
                  }}</span>
                  <br />
                  <span class="caption">Status: </span>
                  <span class="caption font-weight-medium">{{
                    item.paymentStatus
                  }}</span>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item>
            <v-timeline-item v-else-if="item.actionType.toLowerCase() === 'printer status'" :key="Math.random()"
              icon="mdi-printer" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <span class="caption">Action: </span>
                  <span class="caption font-weight-medium">{{
                    item.actionType
                  }}</span>
                  <br />
                  <span class="caption">Paper: </span>
                  <span class="caption font-weight-medium">
                    {{ item.paper }}
                  </span>
                  <br />
                  <span class="caption">Printer Status: </span>
                  <span class="caption font-weight-medium">{{
                    item.printer
                  }}</span>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item>
            <v-timeline-item v-else-if="item.actionType.toLowerCase() === 'print job'" :key="Math.random()"
              icon="mdi-printer" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <span class="caption">Action: </span>
                  <span class="caption font-weight-medium">{{
                    item.actionType
                  }}</span>
                  <br />
                  <span class="caption">Status: </span>
                  <span class="caption font-weight-medium">{{
                    item.status
                  }}</span>
                  <br />
                  <span class="caption">No. of Documents: </span>
                  <span class="caption font-weight-medium">{{
                    item.numberOfDocuments
                  }}</span>
                  <br />
                  <span class="caption">Total page count: </span>
                  <span class="caption font-weight-medium">{{
                    item.numberOfPages
                  }}</span>
                  <br />
                  <span class="caption">Print Cost: </span>
                  <span class="caption font-weight-medium">{{
                    item.printCost
                  }}</span>
                  <br />
                  <span class="caption">Comment: </span>
                  <span class="caption font-weight-medium">{{
                    item.comment
                  }}</span>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item>
            <v-timeline-item v-else-if="item.actionType.toLowerCase() === 'scan job'" :key="Math.random()"
              icon="mdi-scanner" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <span class="caption">Action: </span>
                  <span class="caption font-weight-medium">{{
                    item.actionType
                  }}</span>
                  <br />
                  <span class="caption">Scanner Status: </span>
                  <span class="caption font-weight-medium">{{
                    item.status
                  }}</span>
                  <br />
                  <span class="caption">Pages Scanned: </span>
                  <span class="caption font-weight-medium">{{
                    item.pagesScanned
                  }}</span>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item>
          </div>
          <!-- </template>
          </v-virtual-scroll> -->
        </v-timeline>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { color } from "d3";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "userSession",
  props: {
    userSessionList: Array,
    loading: Boolean,
  },
  data() {
    return {
      timeline: null,
      selected: null,
      rowColor: null,
      selectedSessionId: null,
      count: 0,
      search: '',
      userSessionHeader: [
        { text: "Session ID", align: "id", value: "id", filterable: true, sortable: false },
        { text: "Date", value: "userSessionDate", filterable: false, sortable: false },
        { text: "Start Time", value: "userSessionStartTime", filterable: false, sortable: false },
        { text: "End Time", value: "userSessionEndTime", filterable: false, sortable: false },
        { text: "Duration", value: "userSessionDuration", filterable: false, sortable: false },
      ],
      viewPortHeight: window.innerHeight / 2,
      sessionTimelineHeight: window.innerHeight / 2.25,
    };
  },
  computed: {
    ...mapGetters(["kioskAnalyticsDimension", "getPaymentToSession"]),
    // viewPortHeight() {
    //   return window.innerHeight;
    // },
  },
  methods: {
    getTimeline(item) {
      //console.log('Session Logs: ', item.userSessionLogs.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp)))
      this.selectedSessionId = item.id;
      this.selected = this.selected === item.id ? null : item.id;
      this.timeline = item.userSessionLogs

      // let temp = item.userSessionLogs.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp))
      // console.log('Sorted List: ', temp)
      // this.timeline = temp;
    },
    getRowClass(item) {
      // Apply the class only to the selected row
      return this.selected === item.id ? 'selected-row' : '';

    },
    showPaymentSessionLink(searchedSession) {
      this.search = searchedSession
      this.getTimeline(this.userSessionList.filter(doc => doc.id === searchedSession)[0])
      this.getRowClass(this.userSessionList.filter(doc => doc.id === searchedSession)[0])
    }
  },
  watch: {
    userSessionList(newVal) {
      this.timeline = null
    },
    getPaymentToSession(newVal) {
      this.showPaymentSessionLink(newVal)
    },
    selected(newVal) {
      this.count = 0
    }
  },
  beforeMount() {
    this.search = this.getPaymentToSession
  },
  beforeDestroy() {
    this.search = null
    this.$store.commit("setPaymentToSession", null)
  },
  mounted() {
    this.getTimeline(this.userSessionList[0])
    this.getRowClass(this.userSessionList[0])
  },
  filters: {
    formatStringTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LTS");
    },
    trimLength(val) {
      if (val === null || val === "") return val;
      if (val.length <= 50) {
        return val;
      }
      return `${val.substring(0, 80)}...`;
    },
    formatTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LT");
    },
    formatDate(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("L");
    },
    formatDuration(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("mm:ss");
    },
  },
};
</script>

<style>
tr.v-data-table__selected {
  background: #1976d2 !important;
  color: #ffff !important;
}

.selected-row {
  background-color: #0D47A1 !important;
  color: white;
  /* Change this to the color you want */
}
</style>