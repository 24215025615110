<template>
    <v-data-table fixed-header :items-per-page=-1 hide-default-footer single-select sort-by="timeStamp"
        :sort-desc="true" :height="kioskAnalyticsDimension.qnaAnalyticsheight" :loading="loading" :items="paymentsList"
        :headers="paymentsHeader" item-key="id" single-expand>
        <template v-slot:item.timeStamp="{ item }">
            {{ item.timeStamp | formatStringTime }}
        </template>
        <template v-slot:item.amount="{ item }">
            $ {{ item.amount }}
        </template>
        <template v-slot:item.transactionId="{ item }">
            {{ item.transactionId | formatString }}
        </template>
        <template v-slot:item.status="{ item }">
            <v-chip :color="getColor(item.status)">{{ item.status }}</v-chip>
        </template>
        <template v-slot:item.session="{ item }">
            <v-icon @click="showItemSession(item)">mdi-content-copy</v-icon>
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment'
import { color } from 'd3';
export default {
    name: 'paymentAnalytics',
    props: {
        paymentsList: Array,
        loading: Boolean,
    },
    data() {
        return {
            paymentsHeader: [
                { text: 'Session Id', align: 'start', value: 'applicationSessionId' },
                { text: 'Location', value: 'location' },
                { text: 'MSB Transaction Id', value: 'transactionId' },
                { text: 'Payment Amount', align: '', value: 'amount' },
                { text: 'Status', value: 'status' },
                { text: 'TimeStamp', value: 'timeStamp' },
                { text: 'Copy Session Id', value: 'session' }
            ]
        }
    },
    computed: {
        ...mapGetters(["kioskAnalyticsDimension"])
    },
    filters: {
        formatStringTime(val) {
            if (!val) { return '-' }
            return moment(val).format('LTS')
        },
        formatString(val) {
            if (!val) { return '-' }
            return val
        }
    },
    methods: {
        showItemSession(item) {
            this.$store.commit("setPaymentToSession", item.applicationSessionId)
        },
        getColor(status) {
            if (status.toLowerCase() === 'success') {
                return 'success'
            } else {
                return 'error'
            }
        }
    }
}
</script>