<template>
    <div id="chart-container1"></div>
</template>
    
<script>
import * as d3 from 'd3';
import { mapGetters } from 'vuex';
export default {
    name: 'languageBarChart',
    props: {
        userSessionList: Array,
    },
    computed: {
        ...mapGetters(['kioskAnalytics', 'languageBarChart', 'kioskAnalyticsDimension', 'windowSize'])
    },
    watch: {
        kioskAnalytics(newVal) {
            this.createBarChart();
        },
        windowSize(newVal) {
            this.createBarChart();
        }
    },
    mounted() {
        //this.createBarChart();
    },
    methods: {
        createBarChart() {
            d3.select('#chart-container1').selectAll('*').remove();
            const width = this.kioskAnalyticsDimension.languageBarChartDimension.width;
            const height = this.kioskAnalyticsDimension.languageBarChartDimension.height;
            const margin = { top: 20, right: 20, bottom: 50, left: 55 };

            const svg = d3.select('#chart-container1')
                .append('svg')
                .attr('width', width)
                .attr('height', height);

            const categories = this.languageBarChart.map(d => d.category);

            const xScale = d3.scaleBand()
                .domain(categories)
                .range([margin.left, width - margin.right])
                .padding(0.5);

            const yScale = d3.scaleLinear()
                .domain([0, d3.max(this.languageBarChart, d => d.value)])
                .nice()
                .range([height - margin.bottom, margin.top]);

            svg.append('g')
                .attr('class', 'x-axis')
                .attr('transform', `translate(0,${height - margin.bottom})`)
                .call(d3.axisBottom(xScale));

            svg.append('text')
                .attr('x', width / 2)
                .attr('y', height - margin.bottom + 40)
                .attr('text-anchor', 'middle')
                .style('font-size', '10px')
                .text('Languages');

            svg.append('g')
                .attr('class', 'y-axis')
                .attr('transform', `translate(${margin.left},0)`)
                .call(d3.axisLeft(yScale));

            svg.append('text')
                .attr('transform', 'rotate(-90)')
                .attr('x', -height / 2)
                .attr('y', margin.left - 40)
                .attr('text-anchor', 'middle')
                .style('font-size', '10px')
                .text('User Sessions');
                
            if (this.languageBarChart.every(item => item.value === 0)) {
                svg
                    .append("text")
                    .attr("x", width / 2)
                    .attr("y", height / 2)
                    .attr("text-anchor", "middle")
                    .text("No Data Available");
            } else {
                svg.selectAll('rect')
                    .data(this.languageBarChart)
                    .enter()
                    .append('rect')
                    .attr('x', d => xScale(d.category))
                    .attr('y', d => yScale(d.value))
                    .attr('width', xScale.bandwidth())
                    .attr('height', d => height - margin.bottom - yScale(d.value))
                    .attr('fill', 'steelblue');
            }
        },
    },
};
</script>
    
<style scoped>
/* Add any necessary styles for the chart */
</style>
    